<template>
  <v-container fluid>
    <v-card-title>
      푸시 메시지
      <v-spacer />
      <v-btn outlined to="/notification/create">
        <v-icon left>mdi-plus</v-icon>
        등록
      </v-btn>
    </v-card-title>
    <v-card outlined>
      <v-data-table
        :headers="headers"
        :items="listNotification"
        :items-per-page="input.offset"
        sort-by="id"
        sort-desc
        hide-default-footer
        @click:row="(row) => $router.push(`${$route.path}/${row.id}`)"
      >
        <template v-slot:item.reservationTime="{ item }">
          {{ day(item.reservationTime) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            v-show="item.column || item.notice"
            depressed
            dark
            :color="item.column ? 'deep-purple accent-2' : 'orange'"
            @click.stop.prevent="btnClick(item)"
          >
            {{ item.column ? `칼럼(${item.column.id})` : item.notice ? `공지(${item.notice.id})` : null }}
          </v-btn>
        </template>

        <template v-slot:footer>
          <v-divider />
          <v-container fluid class="row" no-gutters>
            <v-col cols="1">
              <v-select v-model="input.offset" :items="[5, 10, 15, 25, 50, 100]" outlined dense hide-details />
            </v-col>
            <v-spacer />
            <v-col>
              <v-pagination v-model="input.pageNumber" total-visible="13" :length="length" circle color="#ffd600" @input="getListNotification" />
            </v-col>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, watch, toRefs } from '@vue/composition-api'
import { day } from '@/helper'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      listNotification: [],
      length: 1,
      input: {
        page: 1,
        pageNumber: 1,
        offset: 15,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: '푸시 문구', value: 'title' },
        { text: '푸시 하단 문구', value: 'content' },
        { text: '예약일시', value: 'reservationTime' },
        { text: null, value: 'actions' },
      ],
    })

    const getListNotification = async () => {
      try {
        const listNotification = await root.$store.dispatch('notification/getListNotification', {
          offset: state.input.offset,
          pageNumber: state.input.pageNumber,
          isSent: false,
        })

        state.listNotification = listNotification.data

        state.length = Math.ceil(listNotification.totalCount / state.input.offset)
      } catch (err) {
        alert(err)
        console.log(err)
      }
    }

    const btnClick = (row) => {
      if (row.column && row.column.id) root.$router.push(`column/${row.column.id}`)
      else if (row.notice && row.notice.id) root.$router.push(`notice/${row.notice.id}`)
    }

    onBeforeMount(() => {
      getListNotification()
    })

    watch(
      () => state.input.offset,
      () => {
        state.page = 1
        getListNotification()
      },
    )

    return {
      ...toRefs(state),
      getListNotification,
      btnClick,
      day,
    }
  },
})
</script>
